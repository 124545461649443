import images from "../../../images";
import WalletDetails from "../../WalletDetails";
import { useAccount, useNetwork } from "wagmi";
import { useDynamicContext } from "@dynamic-labs/sdk-react-core";
import useStore from "../../../zustand/store";
import Down from "../../Svg/down";
import WalletIcon from "../../Svg/walletIcon";
import useWalletDetails from "../../../hooks/useWalletDetails";
import { Skeleton } from "@mui/material";
export default function CoinCard({
  chainData,
  setShowExchangeList,
  handleShowWallet,
  setConnectWalletField,
  coinData,
  show,
}) {
  const configuration = useStore((state) => state.configuration);
  const { isConnected, isConnecting, isReconnecting } = useAccount();
  const solanaConnector = useDynamicContext();
  const { getWallet, isAnyWalletConnected } = useWalletDetails({ chainData });
  return (
    <div className="bw-w-[50%] ">
      <p className="bw-text-sm bw-font-medium bw-mb-1 bw-text-text-secondary">
        {show == "from" ? "Give" : "Get"}
      </p>
      <div
        style={{
          boxShadow:
            (configuration &&
              configuration.customTheme &&
              configuration.customTheme?.shadow?.boxShadow) ||
            "1px 1px 7px rgba(0, 0, 0, 0.15)",
        }}
        className="bw-w-full bw-h-[78px] bw-pl-4 md:bw-pl-4 bw-flex bw-items-center bw-gap-x-2 md:bw-gap-x-4 bw-bg-background-card bw-rounded-lg bw-border  bw-border-border-primary"
      >
        <div
          className={`bw-w-[36px] bw-h-[36px] bw-flex bw-items-center bw-justify-center bw-rounded-[50%]  bw-relative ${
            !coinData.image
              ? "bw-bg-background-secondary bw-border bw-border-transparent"
              : "bw-border bw-border-border-primary"
          }`}
        >
          {coinData.image ? (
            <img
              src={coinData.image}
              className="bw-rounded-[50%] bw-object-fill "
              alt="img"
              width={35}
              height={35}
            />
          ) : (
            <></>
          )}
          <div className="bw-w-[21px] bw-h-[21px] bw-border bw-border-border-primary bw-absolute bw-bottom-[-2px] bw-right-[-5px] bw-bg-text-secondary bw-rounded-[50%]">
            {chainData.image ? (
              <img
                className="bw-rounded-[50%] "
                src={chainData.image}
                alt="img"
              />
            ) : (
              <></>
            )}
          </div>
        </div>
        <div
          onClick={() => {
            setShowExchangeList(show);
          }}
          className="bw-cursor-pointer"
        >
          <div className="bw-flex bw-min-w-[78px] bw-min-h-[28px] bw-items-center bw-gap-x-2">
            {coinData?.coin?.length ? (
              <p className="bw-text-xl bw-font-bold bw-text-text-primary">
                {coinData.coin}
              </p>
            ) : (
              <p className="bw-text-sm bw-text-text-primary bw-font-bold">
                Select Token
              </p>
            )}

            <Down
              fill={
                coinData?.coin?.length
                  ? "var(--tw-text-primary)"
                  : "var(--tw-text-secondary)"
              }
            />
          </div>
          {chainData?.chain?.length ? (
            <p className="bw-text-sm bw-font-normal bw-text-text-secondary">
              {`on ${chainData.chain}`.length > 12
                ? `on ${chainData.chain.substring(0, 12)}..`
                : `on ${chainData.chain}`}
            </p>
          ) : (
            <p className="bw-text-sm bw-font-bold  bw-text-text-primary">
              & Chain
            </p>
          )}
        </div>
      </div>
      <div className="bw-mt-2 bw-flex bw-justify-end">
        {
          // isReconnecting ? (
          //   <Skeleton
          //     className="bw-w-[100px] bw-mb-1 bw-bg-background-loaderbar bw-rounded-xl bw-min-h-[25px]"
          //     variant="rectangular"
          //   />
          // ) :
          isAnyWalletConnected ? (
            <WalletDetails walletDetails={getWallet(chainData)} />
          ) : (
            <button
              onClick={() => {
                setConnectWalletField(show);
                handleShowWallet();
              }}
              className="bw-mb-1 bw-relative bw-min-w-[100px] bw-flex bw-justify-center bw-items-center bw-gap-x-1 bw-w-[84px] bw-h-[25px]  bw-text-xs bw-font-medium bw-px-2 bw-rounded-xl bw-border bw-border-border-primary bw-bg-background-container bw-text-text-primary"
            >
              <p className="bw-mr-3">Connect</p>
              <div className="bw-absolute bw-right-2">
                <WalletIcon fill={"var(--tw-text-secondary)"} />
              </div>
            </button>
          )
        }
      </div>
    </div>
  );
}
