import useStore from "../../zustand/store";
import CloseButton from "../Svg/closeButton";
import ConnectWalletProgress from "../ConnectWalletProgress";
import BackButton from "../Svg/backButton";
import { useConnect } from "wagmi";
import { useWallet } from "@tronweb3/tronwallet-adapter-react-hooks";
import { useWalletItemActions } from "@dynamic-labs/sdk-react-core";
export default function ConnectorLoader({
  selectedWallet,
  selectedConnector,
  handleConnect,
  connectWalletField,
  isSolConnecting,
  handleOtherWalletConnecting,
}) {
  const configuration = useStore((state) => state.configuration);
  const fromChain = useStore((state) => state.fromChain);
  const toChain = useStore((state) => state.toChain);
  const chainObj = { from: fromChain, to: toChain };
  const gradient = useStore((state) => state.gradient);
  const { connected, address, select, connect } = useWallet();
  const { openWallet } = useWalletItemActions();
  const { data, isSuccess } = useConnect();
  return (
    <div className="bw-flex bw-w-full bw-flex-col bw-justify-center bw-items-center">
      <div className="bw-flex bw-w-full bw-relative bw-justify-center bw-mb-2">
        <button
          onClick={() => {
            handleOtherWalletConnecting();
          }}
          className="bw-absolute bw-left-0 bw-top-[25%]"
        >
          <BackButton fill={"var(--tw-text-secondary)"} />
        </button>
        <div className="bw-text-lg bw-font-normal bw-text-text-secondary">
          Connect Wallet
        </div>
        <button
          onClick={() => {
            handleOtherWalletConnecting();
          }}
          className="bw-absolute bw-right-0 bw-top-[25%]"
        >
          <CloseButton fill={"var(--tw-text-secondary)"} />
        </button>
      </div>
      <ConnectWalletProgress
        selectedWallet={selectedWallet}
        isSuccess={isSuccess}
        data={data}
        isSolConnecting={isSolConnecting}
      />
      <p className="bw-text-lg bw-mt-2 bw-font-medium bw-text-text-primary">
        Continue in {selectedWallet || ""}
      </p>
      <p className="bw-text-lg bw-font-normal bw-mt-2 bw-text-text-secondary">
        Accept connection request in the wallet
      </p>
      <div
        className={`bw-w-full bw-mt-3 bw-rounded-sm bw-h-full bw-p-[3px] ${
          gradient
            ? gradient
            : `${
                !configuration?.gradientStyle?.background
                  ? "bw-bg-gradient-to-r  bw-from-[#2CFFE4] bw-to-[#A45EFF]"
                  : ""
              }`
        }`}
      >
        <button
          onClick={() => {
            if (chainObj[connectWalletField]?.networkType == "evm") {
              handleConnect(selectedConnector);
            } else if (chainObj[connectWalletField]?.networkType == "sol") {
              openWallet(selectedWallet);
            } else {
              select(selectedWallet);
              connect(selectedWallet);
            }
          }}
          className="bw-w-full bw-flex bw-bg-background-container bw-justify-center bw-items-center bw-h-[50px]  bw-rounded-sm  "
        >
          <span
            style={{ ...configuration.gradientStyle, backgroundClip: "text" }}
            className={`  bw-text-text-primary  bw-text-2xl bw-font-bold `}
          >
            Try Again
          </span>
        </button>
      </div>
    </div>
  );
}
