import { useState, useEffect } from "react";
import { useDynamicContext } from "@dynamic-labs/sdk-react-core";
import truncate from "../../utils/truncate";
import ethertousd from "../../utils/ethertousd";
import useStore from "../../zustand/store";
import styles from "./WidgetForm.module.css";
import { useAccount, useNetwork, useBalance, useSwitchNetwork } from "wagmi";
import controllers from "../../Actions/Controllers";
import convertNative from "../../utils/convertNative";
import useWalletDetails from "../../hooks/useWalletDetails";
import { useWallet } from "@tronweb3/tronwallet-adapter-react-hooks";
export default function TxnButton({
  routesData,
  routes,
  fetchBalance,
  amount,
  isAmountTyped,
  handleShowWallet,
  setConnectWalletField,
  handleConfirmClose,
}) {
  const [nativeCoin, setNativeCoin] = useState({});
  const gradient = useStore((state) => state.gradient);
  const configuration = useStore((state) => state.configuration);
  const fromChain = useStore((state) => state.fromChain);
  const fromCoin = useStore((state) => state.fromCoin);
  const toChain = useStore((state) => state.toChain);
  const toCoin = useStore((state) => state.toCoin);
  const { isConnected, address } = useAccount();
  const { chain } = useNetwork();
  const { getBothWalletConnection, getConnectWallet } = useWalletDetails({});
  const { connected, address: tronAdrress, disconnect } = useWallet();
  const connectWalletName = getConnectWallet(
    fromChain?.networkType,
    toChain?.networkType
  );
  const { switchNetwork } = useSwitchNetwork({
    chainId: Number(fromChain?.chainId || "0"),
    throwForSwitchChainNotSupported: true,
    onError(error) {
      console.log("Error", error);
    },
  });
  const selectedCoinbalance = truncate(
    (fetchBalance.data?.data?.[fromChain?.networkType]?.[fromChain.chainId]?.[
      fromCoin.address
    ]?.balance || 0) /
      (Math.pow(
        10,
        fetchBalance.data?.data?.[fromChain?.networkType]?.[
          fromChain.chainId
        ]?.[fromCoin.address]?.asset?.decimals || 0
      ) ||
        balances?.data?.formatted ||
        0),
    6
  );
  const balances = useBalance({
    address: address,
    token: convertNative(fromCoin?.address || ""),
    chainId: Number(fromChain?.chainId || 0),
  });
  const [ctaInfoText, setCtaInfoText] = useState({
    text: "",
    isGradient: false,
    color: "bw-bg-border-primary",
  });
  async function handleSubmit() {
    if (
      fromChain?.networkType === "evm" &&
      Number(fromChain?.chainId || "0") !== chain?.id
    ) {
      try {
        switchNetwork();
      } catch (err) {
        console.log(err, "err");
      }
    } else {
      handleConfirmClose();
    }
  }

  async function callTokens() {
    try {
      await controllers
        .fetchTokens(fromChain.chainId)
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          res.data?.forEach((item) => {
            if (item.isNative) {
              setNativeCoin(item);
            }
          });
        });
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    callTokens();
  }, [fromChain.chain]);

  const { primaryWallet, isFullyConnected } = useDynamicContext();
  const isBothWalletConnected = getBothWalletConnection(
    fromChain?.networkType,
    toChain?.networkType
  );
  function handleError() {
    let tempGasObj = { amountInEther: 0 };
    let token = {};
    routesData?.fee?.forEach((item) => {
      if (item.type == "network" && fromChain?.chainId == item.token?.chainId) {
        tempGasObj.amountInEther += Number(item.amountInEther);
        token = { ...item };
      }
    });
    let gasObj = { tempGasObj, token };
    let gas =
      truncate(
        ethertousd(
          gasObj?.tempGasObj?.amountInEther,
          gasObj?.token?.token?.decimals
        ) || 0,
        6
      ) || 0;
    let nativeBal =
      truncate(
        fetchBalance.data?.data?.[nativeCoin?.networkType]?.[
          nativeCoin.chainId
        ]?.[nativeCoin?.address]?.balance /
          Math.pow(
            10,
            fetchBalance.data?.data?.[nativeCoin?.networkType]?.[
              nativeCoin.chainId
            ]?.[nativeCoin?.address]?.asset?.decimals || 0
          ),
        6
      ) || 0;
    let coinBalance = selectedCoinbalance;
    if (coinBalance || gas) {
      if (amount > coinBalance) {
        setCtaInfoText({
          text: `Insufficient Balance ${fromCoin.coin || ""}`,
          isGradient: false,
          color: "bw-bg-border-primary",
        });
      } else if (
        (amount <= coinBalance &&
          Number(nativeBal) - amount < gas &&
          fromCoin?.isNative) ||
        (amount <= coinBalance &&
          Number(nativeBal) < gas &&
          !fromCoin?.isNative)
      ) {
        setCtaInfoText({
          text: `Insufficient ${nativeCoin?.symbol || ""} for gas`,
          isGradient: false,
          color: "bw-bg-border-primary",
        });
      } else {
        setCtaInfoText({
          text: "",
          isGradient: true,
          color: gradient ? gradient : styles.gradientbutton,
        });
      }
    }
  }

  useEffect(() => {
    if (!fromCoin.coin.length || !toCoin?.coin.length) {
      setCtaInfoText({
        text: "Select Token",
        isGradient: true,
        color: gradient ? gradient : styles.gradientbutton,
      });
    } else if (!isBothWalletConnected) {
      setCtaInfoText({
        text: ``,
        isGradient: true,
        color: gradient ? gradient : styles.gradientbutton,
      });
    } else if (typeof amount !== "undefined" && Number(amount || 0) <= 0) {
      setCtaInfoText({
        text: "Enter Amount",
        isGradient: false,
        color: "bw-bg-border-primary",
      });
    } else if (routes.isFetching || !isAmountTyped) {
      setCtaInfoText({
        text: "Loading Routes...",
        isGradient: false,
        color: "bw-bg-border-primary",
      });
    } else if (
      isConnected &&
      Number(fromChain?.chainId || "0") !== chain?.id &&
      fromChain?.networkType === "evm"
    ) {
      setCtaInfoText({
        text: `Switch Chain to ${fromChain.chain}`,
        isGradient: true,
        color: gradient ? gradient : styles.gradientbutton,
      });
    } else {
      handleError();
    }
  }, [
    fromCoin,
    toCoin,
    amount,
    fromChain,
    balances.data?.formatted,
    routesData,
    fetchBalance.data,
    isConnected,
    nativeCoin,
    selectedCoinbalance,
    isBothWalletConnected,
    routes.isFetching,
    chain?.id,
    isAmountTyped,
  ]);
  console.log("callled");
  function handleDisable() {
    return isBothWalletConnected
      ? routes.isFetching
        ? true
        : ctaInfoText.text.length
        ? ctaInfoText.text?.toLowerCase() ==
          `switch chain to ${fromChain.chain?.toLowerCase()}`
          ? false
          : true
        : !routes.data?.data?.quotes?.length
        ? true
        : false
      : false;
  }
  return (
    <button
      disabled={handleDisable()}
      style={
        !ctaInfoText?.isGradient === false
          ? {
              ...configuration?.gradientStyle,
              boxShadow:
                (configuration &&
                  configuration.customTheme &&
                  configuration.customTheme?.shadow?.boxShadow) ||
                "1px 1px 7px rgba(0, 0, 0, 0.15)",
            }
          : {
              boxShadow:
                (configuration &&
                  configuration.customTheme &&
                  configuration.customTheme?.shadow?.boxShadow) ||
                "1px 1px 7px rgba(0, 0, 0, 0.15)",
            }
      }
      className={`bw-w-full bw-rounded-sm bw-h-[52px] ${
        ctaInfoText?.isGradient ? "bw-p-[3px]" : "bw-p-[1px]"
      } bw-mt-0 bw-border-0 bw-relative bw-z-0  disabled:bw-opacity-70 ${
        ctaInfoText.color
      } ${ctaInfoText.text.length > 29 ? "bw-text-xl" : "bw-text-2xl"} ${
        ctaInfoText?.isGradient
          ? "bw-font-bold bw-text-text-primary"
          : "bw-font-normal bw-text-text-secondary"
      }`}
      onClick={() => {
        if (!isBothWalletConnected) {
          handleShowWallet(true);
          if (
            (fromChain?.networkType !== "evm" && isConnected) ||
            (fromChain?.networkType !== "sol" &&
              isFullyConnected &&
              primaryWallet !== null) ||
            (fromChain?.networkType !== "tron" && connected && tronAdrress)
          ) {
            setConnectWalletField("from");
          } else if (
            (isConnected && toChain?.networkType !== "evm") ||
            (isFullyConnected && toChain?.networkType !== "sol") ||
            (toChain?.networkType !== "tron" && connected && tronAdrress)
          )
            setConnectWalletField("to");
          else {
            setConnectWalletField("from");
          }
        } else handleSubmit();
      }}
    >
      <div className="bw-rounded-sm bw-bg-background-container bw-flex bw-items-center bw-justify-center b-w-full bw-h-full">
        <p className={`bw-relative bw-z-20`}>
          {isBothWalletConnected
            ? ctaInfoText.text.length
              ? ctaInfoText.text
              : "Start Transaction"
            : `Connect ${connectWalletName} Wallet`}
        </p>
      </div>
    </button>
  );
}
