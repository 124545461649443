import React, { useState, useEffect } from "react";
import { useConnect, useAccount } from "wagmi";
import getIsInstalled from "./getisInstalled";
import images from "../../images";
import SolanaWallet from "../SolanaWallet";
import useStore from "../../zustand/store";
import BackButton from "../Svg/backButton";
import { useDynamicContext } from "@dynamic-labs/sdk-react-core";
import InstalledIcon from "../InstalledIcon";
import CloseButton from "../Svg/closeButton";
import TronWallet from "../TronWallet";
import { useWallet } from "@tronweb3/tronwallet-adapter-react-hooks";
import useWalletDetails from "../../hooks/useWalletDetails";
import ConnectorLoader from "./connectorLoader";
export default function SelectWallet({
  handleShowWallet,
  connectWalletField,
  portfolio,
}) {
  const { connectAsync, data, connectors, isSuccess, isLoading, error } =
    useConnect();
  const fromChain = useStore((state) => state.fromChain);
  const toChain = useStore((state) => state.toChain);
  const { primaryWallet, isFullyConnected } = useDynamicContext();
  const [selectedWallet, setSelectedWallet] = useState("");
  const [showLoading, setShowLoading] = useState(true);
  const [selectedConnector, setSelectedConnector] = useState();
  const [isSolConnecting, setIsSolanaConnecting] = useState(false);
  const [isTronConnecting, setIsTronConnecting] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const { connected, address } = useWallet();
  const { handleEVMDisable } = useWalletDetails({ fromChain, toChain });
  const isEvmDisable = handleEVMDisable(connectWalletField);
  const { isConnected } = useAccount();

  async function handleConnect(connector) {
    try {
      let result = await connectAsync({ connector });
      console.log(result, "results");
      setErrorMsg((prev) => {
        if (prev?.length && result) {
          handleShowWallet();
        }
        return "";
      });
    } catch (err) {
      setErrorMsg(err.details);
    }
  }
  useEffect(() => {
    let timeout;
    if (data && isSuccess)
      timeout = setTimeout(() => {
        handleShowWallet();
      }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, [isSuccess, data]);

  const { metamaskicon, injectedicon, coinbaseicon, walletconnecticon } =
    images;
  const walletIcons = {
    injected: injectedicon,
    metaMask: metamaskicon,
    coinbaseWallet: coinbaseicon,
    walletConnect: walletconnecticon,
  };

  const configuration = useStore((state) => state.configuration);
  const chainObj = { from: fromChain, to: toChain };
  function handleSolConnecting(val, wallet) {
    if (wallet) {
      setSelectedWallet(wallet);
    }
    setIsSolanaConnecting(val);
    portfolio && setShowLoading(false);
  }
  function handleOtherWalletConnecting() {
    setShowLoading(true);
    setIsSolanaConnecting(false);
    setIsTronConnecting(false);
  }
  function handleTronConnection(val, wallet) {
    if (wallet) setSelectedWallet(wallet);
    setIsTronConnecting(val);
    portfolio && setShowLoading(false);
  }
  useEffect(() => {
    let timeout;
    if (isFullyConnected && primaryWallet !== null && isSolConnecting) {
      timeout = setTimeout(() => {
        handleShowWallet();
        handleSolConnecting(false);
      }, 1000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isFullyConnected, primaryWallet, isSolConnecting]);

  useEffect(() => {
    let timeout;
    if (connected && address && isTronConnecting) {
      timeout = setTimeout(() => {
        handleShowWallet();
        handleTronConnection(false);
      }, 1000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [connected, address, isTronConnecting]);

  useEffect(() => {
    !portfolio &&
      setShowLoading(
        (!isLoading &&
          !data &&
          chainObj[connectWalletField]?.networkType == "evm") ||
          (!isSolConnecting &&
            chainObj[connectWalletField]?.networkType == "sol") ||
          (!isTronConnecting &&
            chainObj[connectWalletField]?.networkType == "tron")
      );
  }, [isLoading, data, connectWalletField, isSolConnecting, portfolio]);
  return showLoading ? (
    <div className="">
      <div className="bw-flex bw-relative bw-justify-center bw-mb-2">
        <button
          onClick={handleShowWallet}
          className="bw-absolute bw-left-0 bw-top-[25%]"
        >
          <BackButton fill={"var(--tw-text-secondary)"} />
        </button>
        <div className="bw-text-lg bw-font-medium bw-text-text-secondary">
          Connect Wallet
        </div>
        <button
          onClick={handleShowWallet}
          className="bw-absolute bw-right-0 bw-top-[25%]"
        >
          <CloseButton fill={"var(--tw-text-secondary)"} />
        </button>
      </div>
      <div
        className={`${
          portfolio ? "bw-h-[630px]" : "bw-h-[400px]"
        } bw-overflow-auto bw-pt-2 bw-mt-4 `}
      >
        {connectors.map((item, i) => {
          return (
            <div
              key={i}
              onClick={() => {
                setSelectedWallet(item.id);
                setSelectedConnector(item);
                handleConnect(item);
                portfolio && setShowLoading(false);
              }}
              style={{ cursor: "pointer" }}
              className={`bw-w-[95%] bw-mx-auto hover:bw-shadow-sm bw-font-medium hover:bw-font-bold bw-text-text-secondary hover:bw-text-text-primary hover:bw-scale-105 bw-flex bw-gap-x-3 bw-mb-4 bw-px-3 bw-justify-start bw-items-center bw-h-[50px] bw-relative bw-border bw-rounded-md bw-border-border-primary ${
                (portfolio ? !isConnected : item.ready && !isEvmDisable)
                  ? ""
                  : "bw-opacity-50 bw-pointer-events-none"
              } 
              `}
            >
              {getIsInstalled(item.id.toLowerCase()) ? (
                <div className="bw-absolute bw-top-1 bw-right-2">
                  <InstalledIcon
                    startColor={configuration?.gradientStyle?.spinnerColor}
                    stopColor={configuration?.gradientStyle?.stopColor}
                  />
                </div>
              ) : (
                <></>
              )}
              <img
                src={walletIcons[item.id]}
                width={40}
                height={40}
                alt="img"
              />
              <p className="bw-text-sm bw-w-max ">{item.name}</p>
            </div>
          );
        })}
        <TronWallet
          handleTronConnection={handleTronConnection}
          connectWalletField={connectWalletField}
          fromChain={fromChain}
          toChain={toChain}
          portfolio={portfolio}
        />
        <SolanaWallet
          handleShowWallet={handleShowWallet}
          fromChain={fromChain}
          toChain={toChain}
          connectWalletField={connectWalletField}
          handleSolConnecting={handleSolConnecting}
          portfolio={portfolio}
        />
      </div>
      <p className="bw-bg-background-container bw-text-sm bw-font-normal bw-text-center bw-mt-2">
        {errorMsg}
      </p>
    </div>
  ) : (
    <ConnectorLoader
      selectedWallet={selectedWallet}
      selectedConnector={selectedConnector}
      handleConnect={handleConnect}
      connectWalletField={connectWalletField}
      handleOtherWalletConnecting={handleOtherWalletConnecting}
      isSolConnecting={isSolConnecting}
    />
  );
}
