import { useState, useEffect } from "react";
import useStore from "../zustand/store";
import { isEmpty } from "lodash";
let blurDate;
let timePauseVal;
let interval;
export default function useQuoteTimer({
  confirmRoute,
  setConfirmRoute,
  routes,
  routesData,
  isRoutesEnabled,
}) {
  const [quoteTimer, setQuoteTimer] = useState();
  useEffect(() => {
    if (confirmRoute) {
      clearInterval(interval);
    }
  }, [confirmRoute]);
  useEffect(() => {
    if (
      quoteTimer === 0 &&
      document.visibilityState === "visible" &&
      !confirmRoute &&
      isRoutesEnabled
    ) {
      routes.refetch();
    }
  }, [quoteTimer, confirmRoute, isRoutesEnabled]);
  useEffect(() => {
    setQuoteTimer(
      (routes?.data?.data?.quotes?.[0]?.deadline ||
        routes?.data?.data?.quotes?.[0]?.estimatedTimeInSeconds) - 1
    );
  }, [routes.isSuccess]);
  useEffect(() => {
    if (!isEmpty(routesData)) {
      setQuoteTimer(
        (routesData?.deadline || routesData?.estimatedTimeInSeconds) - 1
      );
      document.addEventListener("visibilitychange", handleVisible);
    }
    return () => {
      document.removeEventListener("visibilitychange", handleVisible);
    };
  }, [routesData]);
  function handleIntervalBlur() {
    blurDate = Date.now();
    setQuoteTimer((prev) => {
      timePauseVal = prev;
      return prev;
    });
  }
  function handleIntervalFocus() {
    // console.log("focused");
    let focusDate = Date.now();
    let diffInSec = Math.round((focusDate - blurDate) / 1000);
    if (60 - timePauseVal + diffInSec > 60) {
      setConfirmRoute((prev) => {
        if (!prev) {
          routes.refetch();
        }
        return prev;
      });
    } else {
      if (timePauseVal && diffInSec) setQuoteTimer(timePauseVal - diffInSec);
    }
  }
  function handleVisible() {
    if (document.visibilityState === "visible") {
      handleIntervalFocus();
    } else {
      handleIntervalBlur();
    }
  }
  useEffect(() => {
    if (quoteTimer) {
      if (isEmpty(routesData)) {
        if (interval) clearInterval(interval);
      } else {
        interval = setInterval(() => {
          setQuoteTimer((prev) => {
            if (prev == 0) {
              return timerValue?.timer;
            } else {
              return prev - 1;
            }
          });
        }, 1000);
      }
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [quoteTimer, routesData]);
  const timerValue = useStore((state) => state.timerValue);
  return { quoteTimer, setQuoteTimer };
}
