const images = {
  tradebridge: require("./Assets/tradebridge.svg").default,
  arrowprocess: require("./Assets/arrowprocess.png"),
  backbutton: require("./Assets/backbutton.svg").default,
  close: require("./Assets/close.svg").default,
  coinbaseicon: require("./Assets/coinbaseicon.svg").default,
  copy: require("./Assets/copy.svg").default,
  down: require("./Assets/down.svg").default,
  downroute: require("./Assets/downroute.svg").default,
  edit: require("./Assets/edit.svg").default,
  failedimg: require("./Assets/failedimg.svg").default,
  filter: require("./Assets/filter.svg").default,
  gas: require("./Assets/gas.svg").default,
  greentick: require("./Assets/greentick.svg").default,
  handstep: require("./Assets/handstep.svg").default,
  history: require("./Assets/history.svg").default,
  info: require("./Assets/info.svg").default,
  injectedicon: require("./Assets/injectedicon.svg").default,
  metamaskicon: require("./Assets/metamaskicon.svg").default,
  refresh: require("./Assets/refresh.svg").default,
  reverse: require("./Assets/reverse.svg").default,
  routeicon: require("./Assets/routeicon.svg").default,
  search: require("./Assets/search.svg").default,
  step1: require("./Assets/step1.svg").default,
  step2: require("./Assets/step2.svg").default,
  stepstick: require("./Assets/stepstick.svg").default,
  success: require("./Assets/success.png"),
  tick: require("./Assets/tick.png"),
  time: require("./Assets/time.svg").default,
  walletconnecticon: require("./Assets/walletconnecticon.svg").default,
  installedicon: require("./Assets/installedicon.svg").default,
  loadroute: require("./Assets/loadingroute.svg").default,
  walleticon: require("./Assets/walleticon.svg").default,
  shareicon: require("./Assets/share.svg").default,
  modegas: require("./Assets/modegas.svg").default,
  modegasless: require("./Assets/modegasless.svg").default,
  chainlink: require("./Assets/chainlink.svg").default,
  hamburger: require("./Assets/hamburger.svg").default,
  swapicon: require("./Assets/swapicon.svg").default,
  clocknew: require("./Assets/clocknew.svg").default,
  exchange: require("./Assets/exchange.svg").default,
  refetchroute: require("./Assets/refetchRoute.svg").default,
  portfolio: require("./Assets/portfolio.svg").default,
  disconnecticon: require("./Assets/disconnect.svg").default,
  b: require("./Assets/b.svg").default,
  clockicon: require("./Assets/clockicon.svg").default,
  step: require("./Assets/step.svg").default,
  routesback: require("./Assets/routesback.svg").default,
  maxop: require("./Assets/maxop.svg").default,
  lowgas: require("./Assets/lowgas.svg").default,
  fast: require("./Assets/fast.svg").default,
  gasnew: require("./Assets/gasnew.svg").default,
  phantom: require("./Assets/phantom.svg").default,
  solflare: require("./Assets/solflare.svg").default,
  slope: require("./Assets/slope.svg").default,
  backpacksol: require("./Assets/backpacksol.svg").default,
  magiceden: require("./Assets/magiceden.svg").default,
  exchangeicon: require("./Assets/exchangeicon.svg").default,
  successGif: require("./Assets/success.gif"),
  tronLink: require("./Assets/tronlinkicon.svg").default,
  barchartdisconnect: require("./Assets/barchartdisconnect.png"),
  defaultCoin: require("./Assets/defaultcoin.svg").default,
  errornew: require("./Assets/errornewicon.svg").default,
  successnew: require("./Assets/successnew.svg").default,
};

export default images;
