import React from "react";
import TxnUrl from "../Tooltip/TxnUrl";
import ChainLink from "../Svg/chainLink";
import useStore from "../../zustand/store";
function TransactionLinkComp({ i, item }) {
  const persist = useStore((state) => state.persist);
  const txnHashObj = persist?.txnHashObj || [];
  const link = txnHashObj[item.stepId]?.destTxnUrl?.length
    ? txnHashObj[item.stepId]?.destTxnUrl
    : txnHashObj[item.stepId]?.srcTxnUrl;
  console.log(link, "link123");
  return link?.length ? (
    <a target="_blank" className="bw-relative bw-z-50 bw-mt-1" href={link}>
      <TxnUrl>
        <div>
          <ChainLink fill={"var(--tw-text-primary)"} />
        </div>
      </TxnUrl>
    </a>
  ) : (
    <></>
  );
}

export default TransactionLinkComp;
