import React from "react";

function Maxop({ fill }) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.820312"
        y="1.4248"
        width="14"
        height="14"
        rx="7"
        fill={fill}
        stroke="url(#paint0_linear_739_20205)"
      />
      <path
        d="M7.34417 5.19093C7.49384 4.73028 8.14555 4.73028 8.29522 5.19093L8.53764 5.937C8.73845 6.55504 9.31438 6.97348 9.96422 6.97348H10.7487C11.233 6.97348 11.4344 7.59329 11.0426 7.87799L10.4079 8.33908C9.8822 8.72105 9.66222 9.3981 9.86303 10.0161L10.1054 10.7622C10.2551 11.2229 9.72788 11.6059 9.33602 11.3212L8.70137 10.8601C8.17564 10.4782 7.46375 10.4782 6.93802 10.8601L6.30337 11.3212C5.91152 11.6059 5.38428 11.2229 5.53395 10.7622L5.77637 10.0161C5.97718 9.3981 5.75719 8.72105 5.23146 8.33908L4.59681 7.87799C4.20496 7.59329 4.40635 6.97348 4.89071 6.97348H5.67517C6.32501 6.97348 6.90094 6.55504 7.10176 5.937L7.34417 5.19093Z"
        stroke="black"
      />
      <path
        d="M7.34417 5.19093C7.49384 4.73028 8.14555 4.73028 8.29522 5.19093L8.53764 5.937C8.73845 6.55504 9.31438 6.97348 9.96422 6.97348H10.7487C11.233 6.97348 11.4344 7.59329 11.0426 7.87799L10.4079 8.33908C9.8822 8.72105 9.66222 9.3981 9.86303 10.0161L10.1054 10.7622C10.2551 11.2229 9.72788 11.6059 9.33602 11.3212L8.70137 10.8601C8.17564 10.4782 7.46375 10.4782 6.93802 10.8601L6.30337 11.3212C5.91152 11.6059 5.38428 11.2229 5.53395 10.7622L5.77637 10.0161C5.97718 9.3981 5.75719 8.72105 5.23146 8.33908L4.59681 7.87799C4.20496 7.59329 4.40635 6.97348 4.89071 6.97348H5.67517C6.32501 6.97348 6.90094 6.55504 7.10176 5.937L7.34417 5.19093Z"
        stroke="url(#paint1_linear_739_20205)"
      />
      <path
        d="M7.34417 5.19093C7.49384 4.73028 8.14555 4.73028 8.29522 5.19093L8.53764 5.937C8.73845 6.55504 9.31438 6.97348 9.96422 6.97348H10.7487C11.233 6.97348 11.4344 7.59329 11.0426 7.87799L10.4079 8.33908C9.8822 8.72105 9.66222 9.3981 9.86303 10.0161L10.1054 10.7622C10.2551 11.2229 9.72788 11.6059 9.33602 11.3212L8.70137 10.8601C8.17564 10.4782 7.46375 10.4782 6.93802 10.8601L6.30337 11.3212C5.91152 11.6059 5.38428 11.2229 5.53395 10.7622L5.77637 10.0161C5.97718 9.3981 5.75719 8.72105 5.23146 8.33908L4.59681 7.87799C4.20496 7.59329 4.40635 6.97348 4.89071 6.97348H5.67517C6.32501 6.97348 6.90094 6.55504 7.10176 5.937L7.34417 5.19093Z"
        stroke="black"
        stroke-opacity="0.2"
      />
      <defs>
        <linearGradient
          id="paint0_linear_739_20205"
          x1="18.1645"
          y1="6.26376"
          x2="-8.973"
          y2="6.2369"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#2CFFE4" />
          <stop offset="1" stop-color="#A45EFF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_739_20205"
          x1="10.9776"
          y1="10.0041"
          x2="1.99984"
          y2="2.87371"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#2CFFE4" />
          <stop offset="1" stop-color="#A45EFF" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default Maxop;
