import React from "react";
import { useMemo } from "react";
import useStore from "../../zustand/store";
import Gas from "../Svg/gas";
import truncate from "../../utils/truncate";
function EstGas() {
  const persist = useStore((state) => state.persist);
  const fromChain = persist?.fromChain || {};
  let gasData = useMemo(() => {
    if (persist.route) {
      let tempGasObj = { amountInUSD: 0 };
      let token = {};
      persist?.route?.fee?.forEach((item) => {
        if (
          item.type == "network" &&
          fromChain?.chainId == item.token?.chainId
        ) {
          tempGasObj.amountInUSD += Number(item.amountInUSD);
          token = { ...item };
        }
      });
      return { tempGasObj, token };
    }
  }, [persist.route]);
  console.log("gasData", gasData);
  return (
    <div className="bw-w-full bw-border bw-py-2 bw-mt-5 bw-border-border-primary bw-flex bw-items-center bw-justify-between bw-border-x-0">
      <p className="bw-text-sm bw-text-semibold bw-text-text-secondary">
        Total Est. Fee
      </p>
      <div className="bw-flex bw-items-center bw-gap-x-1">
        <Gas fill={"var(--tw-text-primary)"} />
        <p className="bw-text-sm bw-font-medium bw-text-text-primary">
          {truncate(
            gasData?.token?.amountInEther /
              Math.pow(10, gasData?.token?.token?.decimals),
            6
          ) || 0}
        </p>
        <span className="bw-text-sm bw-font-medium bw-text-text-primary">
          {gasData?.token?.token?.symbol}
        </span>
        <span className="bw-text-sm bw-font-medium bw-text-text-primary">
          {"("}
        </span>
        <p className="bw-text-sm bw-font-medium bw-text-text-primary">
          ${truncate(gasData?.tempGasObj?.amountInUSD || 0, 6) || 0}
        </p>
        <span className="bw-text-sm bw-font-medium bw-text-text-primary">
          {")"}
        </span>
      </div>
    </div>
  );
}

export default EstGas;
