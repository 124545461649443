import Main from "./main";
const SolanaWallet = ({
  connectWalletField,
  fromChain,
  toChain,
  handleSolConnecting,
  portfolio,
}) => {
  return (
    <Main
      fromChain={fromChain}
      toChain={toChain}
      connectWalletField={connectWalletField}
      handleSolConnecting={handleSolConnecting}
      portfolio={portfolio}
    />
  );
};
export default SolanaWallet;
