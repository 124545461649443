import { useWallet } from "@tronweb3/tronwallet-adapter-react-hooks";
import useWalletDetails from "../../hooks/useWalletDetails";
import images from "../../images";
import InstalledIcon from "../InstalledIcon";
import useStore from "../../zustand/store";
export default function TronWallet({
  handleTronConnection,
  connectWalletField,
  fromChain,
  toChain,
  portfolio,
}) {
  const { wallets, select, connected, connect, wallet } = useWallet();
  const { handleTRONDisable } = useWalletDetails({ fromChain, toChain });
  const isTRONDisable = handleTRONDisable(connectWalletField);
  const configuration = useStore((state) => state.configuration);
  function handleConnect(wallet) {
    try {
      select(wallet.adapter.name);
      !connected && connect(wallet);
    } catch (err) {
      console.log(err);
    }
    handleTronConnection(true, wallet.adapter?.name);
  }
  const { tronLink } = images;
  const walletIcons = {
    tronlink: tronLink,
  };
  return wallets.map((wallet, i) => (
    <div
      onClick={() => handleConnect(wallet)}
      style={{ cursor: "pointer" }}
      className={`bw-w-[95%] bw-mx-auto bw-font-medium bw-text-text-secondary hover:bw-font-bold hover:bw-text-text-primary hover:bw-shadow-sm hover:bw-scale-105 bw-flex bw-gap-x-3 bw-mb-4 bw-px-3 bw-justify-start bw-items-center bw-h-[50px] bw-relative bw-border bw-rounded-md bw-border-border-primary ${
        (portfolio ? connected : isTRONDisable)
          ? "bw-opacity-50 bw-pointer-events-none"
          : ""
      } `}
      key={i}
    >
      {walletIcons[wallet?.adapter?.name?.toLowerCase()] ? (
        <img
          src={walletIcons[wallet.adapter.name.toLowerCase()]}
          width={40}
          height={40}
          className="bw-max-h-[40px] bw-rounded-[50%]"
          alt="img"
        />
      ) : (
        <></>
      )}
      <p className="bw-text-sm bw-w-max ">{wallet.adapter.name}</p>
      {wallet.adapter._readyState?.toLowerCase() === "found" ? (
        <div className="bw-absolute bw-top-1 bw-right-2">
          <InstalledIcon
            startColor={configuration?.gradientStyle?.spinnerColor}
            stopColor={configuration?.gradientStyle?.stopColor}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  ));
}
