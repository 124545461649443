import MainPage from "../../Pages/MainPage";
import useStore from "../../zustand/store";
import Portfolio from "../../Pages/Portfolio";
export default function Routes() {
  const route = useStore((state) => state.route);
  function handleRoute() {
    switch (route) {
      case "":
        return <MainPage />;
      case "portfolio":
        return <Portfolio />;
      default:
        return <></>;
    }
  }
  return handleRoute();
}
