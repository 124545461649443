import { useState } from "react";
import { useQuery } from "react-query";
import controllers from "../Actions/Controllers";
export default function useRoutes({ enabled, dependency, payload }) {
  const [errorMessage, setErrorMessage] = useState("");
  const [routesData, setRoutesData] = useState({});
  const routes = useQuery(
    dependency,
    async () => {
      let res = await controllers.fetchRoutes(...payload);
      return res.json();
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retryOnMount: false,
      enabled: enabled,
      onSuccess: (data) => {
        if (data.status == "error") {
          setErrorMessage(data);
        } else {
          setErrorMessage({ error: "" });
        }
        setRoutesData(data?.data?.quotes?.[0] || {});
      },
      onError: (err) => {
        setErrorMessage(err.details);
      },
    }
  );
  return { routes, errorMessage, routesData, setRoutesData };
}
