import {
  useDynamicContext,
  useWalletItemActions,
} from "@dynamic-labs/sdk-react-core";
import { useEffect, useState } from "react";
import images from "../../images";
import { CircularProgress } from "@mui/material";
import InstalledIcon from "../InstalledIcon";
import useStore from "../../zustand/store";
import useWalletDetails from "../../hooks/useWalletDetails";
const Main = ({
  fromChain,
  toChain,
  connectWalletField,
  handleSolConnecting,
  portfolio,
}) => {
  const { primaryWallet, isFullyConnected } = useDynamicContext();
  const [balance, setBalance] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { setShowAuthFlow, walletConnectorOptions } = useDynamicContext();
  const { openWallet } = useWalletItemActions();
  const configuration = useStore((state) => state.configuration);
  const { handleSOLDisable } = useWalletDetails({ fromChain, toChain });
  const isSOLDisable = handleSOLDisable(connectWalletField);
  useEffect(() => {
    if (primaryWallet) {
      primaryWallet.connector.getBalance().then((balance) => {
        setBalance(balance.toString());
      });
    }
  }, [primaryWallet]);
  useEffect(() => {
    if (isFullyConnected && isLoading && primaryWallet !== null) {
      setIsLoading(false);
    }
  }, [isFullyConnected, primaryWallet]);
  const { phantom, solflare, slope, installedicon, magiceden, backpacksol } =
    images;
  const walletIcons = {
    solflare,
    phantom,
    slope,
    backpacksol,
    "magic eden (solana)": magiceden,
  };
  return walletConnectorOptions?.length ? (
    <div>
      {walletConnectorOptions
        .filter((item, i) => {
          if (
            item.name == "Solflare" ||
            item.name == "Slope" ||
            item.name == "Phantom" ||
            item.name == "BackpackSol" ||
            item.name == "Magic Eden (Solana)"
          ) {
            return true;
          }
        })
        .map((item, i) => {
          return (
            <div
              className={` bw-relative bw-flex bw-w-[95%] bw-mx-auto hover:bw-shadow-sm bw-font-medium bw-text-text-secondary hover:bw-font-bold hover:bw-text-text-primary hover:bw-scale-105 bw-items-center bw-gap-x-3 bw-border bw-rounded-md bw-border-border-primary bw-h-[50px] bw-mb-3 bw-px-3 bw-cursor-pointer  ${
                (portfolio ? isFullyConnected : isSOLDisable)
                  ? "bw-pointer-events-none bw-opacity-50"
                  : ""
              }`}
              onClick={() => {
                openWallet(item.name?.toLowerCase());
                setIsLoading(true);
                handleSolConnecting(true, item.name?.toLowerCase());
              }}
            >
              {item.isInstalledOnBrowser ? (
                <div className="bw-absolute bw-top-1 bw-right-2">
                  <InstalledIcon
                    startColor={configuration?.gradientStyle?.spinnerColor}
                    stopColor={configuration?.gradientStyle?.stopColor}
                  />
                </div>
              ) : (
                <></>
              )}
              <img
                src={walletIcons[item.name?.toLowerCase()]}
                width={40}
                height={40}
                className="bw-max-h-[40px]"
                alt="img"
              />
              <p className="bw-text-sm bw-w-max ">{item.name}</p>
            </div>
          );
        })}
    </div>
  ) : (
    <div className="bw-w-full bw-flex bw-justify-center bw-mt-2 bw-text-text-primary">
      <CircularProgress color="inherit" size={14} />
    </div>
  );
};

export default Main;
