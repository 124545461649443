import React, { useContext } from "react";
import useStore from "../../zustand/store";
import { CircularProgress } from "@mui/material";
import CloseIcon from "../Svg/closeIcon";
import StepStick from "../Svg/stepStick";
import images from "../../images";
import { TxnErrorContext } from "../Context/txnErrorContext";
import Lottie from "lottie-react";
import Activestep from "../../lottie/activestep.json";
import SpinnerAnimation from "../../lottie/spinner.json";
function StepsIcons({ allSteps, disableButton, i, arr }) {
  const configuration = useStore((state) => state.configuration);
  const { handstep, errornew, successnew } = images;
  const { isErrorMessage } = useContext(TxnErrorContext);
  return (
    <div className="bw-w-[18px] bw-min-h-full bw-relative bw-flex bw-flex-col bw-justify-center bw-items-center  ">
      {allSteps?.currentStep < i ? (
        <div className="bw-w-[18px] bw-rounded-[50%] bw-h-[18px] bw-bg-background-container bw-border bw-border-border-primary "></div>
      ) : allSteps?.currentStep == i ? (
        disableButton ? (
          <>
            <div className=" bw-w-[18px] bw-h-[18px]   ">
              <Lottie animationData={SpinnerAnimation} loop={true} />
            </div>
          </>
        ) : isErrorMessage ? (
          // <CloseIcon width={18} height={18} fill={"var(--tw-text-primary)"} />
          <img src={errornew} width={27} height={27} alt="img" />
        ) : (
          <Lottie animationData={Activestep} loop={true} />
        )
      ) : (
        <img src={successnew} width={17} height={17} alt="img" />
      )}
      {i !== arr.length - 1 ? (
        <div
          className={`bw-h-[30px] bw-absolute bw-w-[1px] bw-bottom-[-200%] bw-bg-background-secondary`}
        ></div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default StepsIcons;
