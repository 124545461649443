import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import React, { useState } from "react";
import images from "../../images";
import { useAccount, useNetwork, useBalance, useDisconnect } from "wagmi";
import truncate from "../../utils/truncate";
import DisconnectIcon from "../Svg/disconnecticon";
export default function WalletDetails({ walletDetails }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const { address, chain, data, disconnect, ...rest } = walletDetails;
  const open = Boolean(anchorEl);
  const { disconnecticon } = images;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <button
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        id="basic-button"
        style={{ fontSize: "12px" }}
        className="bw-bg-background-container bw-flex bw-items-center bw-justify-center bw-gap-x-2 bw-mb-[4px] bw-w-[100px] bw-h-[25px] bw-rounded-xl bw-border bw-border-border-primary"
      >
        <p className="bw-text-text-primary bw-text-xs bw-font-normal">
          {address?.substring(0, 4) +
            "..." +
            address?.substring(address.length - 4, address.length)}
        </p>
        <div
          onClick={() => {
            disconnect();
          }}
        >
          {/* <img src={disconnecticon} /> */}
          <DisconnectIcon fill={"var(--tw-text-primary)"} />
        </div>
      </button>
    </>
  );
}
