import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import truncate from "../../../utils/truncate";
import images from "../../../images";
import Coins from "../../Tooltip/Coins";
import useStore from "../../../zustand/store";
export default function PortfolioTableRow({ row, chainData }) {
  const { defaultCoin } = images;
  const setRoute = useStore((state) => state.setRoute);
  const setFromChain = useStore((state) => state.setFromChain);
  const setFromCoin = useStore((state) => state.setFromCoin);
  return (
    <TableRow
      className="bw-cursor-pointer bw-block bw-w-full"
      onClick={() => {
        setRoute("");
        setFromChain({
          ...chainData[row.asset?.chainId],
          chain: chainData[row.asset?.chainId]?.name,
        });
        setFromCoin({
          ...row.asset,
          coin: row.asset?.symbol,
          availBal: "",
        });
      }}
    >
      <TableCell
        className="bw-border-b bw-pl-1 bw-w-[50%] bw-bg-background-container  bw-border-border-primary bw-text-xs bw-font-bold bw-text-text-primary"
        component="th"
        scope="row"
      >
        <div className="bw-flex bw-items-center bw-gap-x-2">
          <div className="bw-rounded-[50%]  bw-relative bw-border bw-border-border-primary bw-w-max bw-h-max bw-bg-background-secondary">
            {row?.asset?.image ? (
              <img
                src={row.asset.image}
                className="bw-rounded-[50%]  bw-min-w-[25px] bw-max-h-[25px] bw-min-h-[25px]"
                width={25}
                height={25}
              />
            ) : (
              <img
                src={defaultCoin}
                className="bw-rounded-[50%]  bw-min-w-[25px] bw-max-h-[25px] bw-min-h-[25px]"
                width={25}
                height={25}
              />
            )}
            <div className="bw-absolute bw-border bw-border-border-primary  bw-rounded-[50%] bw-right-[-5px] bw-bg-background-secondary bw-top-[60%]">
              {chainData[row?.asset?.chainId]?.image ? (
                <img
                  src={chainData[row.asset.chainId].image}
                  className="bw-rounded-[50%]  bw-min-w-[16px] bw-max-h-[16px] bw-min-h-[12px]"
                  width={16}
                  height={16}
                />
              ) : (
                <img
                  src={defaultCoin}
                  className="bw-rounded-[50%]  bw-min-w-[12px] bw-max-h-[12px] bw-min-h-[12px]"
                  width={16}
                  height={16}
                />
              )}
            </div>
          </div>
          <div>
            <div className="bw-flex bw-items-center bw-gap-x-1">
              {/* <p className="bw-text-sm bw-text-text-primary bw-font-bold">
                {row.asset?.symbol}
              </p> */}
              {row?.asset?.name?.length > 15 ? (
                <Coins name={row?.asset?.name}>
                  <p className="bw-text-text-primary bw-text-xs bw-font-bold">
                    {row?.asset?.name.substring(0, 15) + "..."}
                  </p>
                </Coins>
              ) : (
                <p className="bw-text-text-primary bw-text-xs bw-font-bold">
                  {row?.asset?.name}
                </p>
              )}
            </div>

            <div className="bw-border bw-w-max bw-mt-[2px] bw-text-xs bw-font-normal bw-px-1 bw-rounded-[4px] bw-text-center bw-text-text-secondary  bw-border-border-primary">
              ${truncate(row?.asset?.lastPrice, 4)}
            </div>
          </div>
        </div>
      </TableCell>
      {/* <TableCell
        className="bw-border-b bw-bg-background-container bw-w-max bw-border-border-primary bw-text-xs bw-font-bold bw-text-text-secondary"
        component="th"
        scope="row"
      >
        ${truncate(row?.asset?.lastPrice, 4)}
      </TableCell> */}
      <TableCell
        className="bw-border-b bw-w-[35%] bw-bg-background-container  bw-max-w-[110px] bw-text-right bw-border-border-primary bw-text-xs bw-font-bold bw-text-text-primary"
        //   component="th"
        scope="row"
      >
        <div className="bw-flex bw-mt-[5px] bw-items-center bw-gap-x-2 bw-justify-end">
          <p className="bw-leading-none bw-flex bw-text-text-primary  bw-font-bold bw-text-xs">
            {truncate(
              Number(row.balance) / Math.pow(10, row.asset?.decimals),
              5
            )}{" "}
            <span>
              {row.asset?.symbol?.length > 8
                ? row.asset?.symbol.substring(0, 8)
                : row.asset?.symbol}
            </span>
          </p>
        </div>
        <div className="bw-text-text-secondary bw-mt-1 bw-font-normal bw-text-xs">
          {chainData[row?.asset?.chainId]?.name || ""}
        </div>
      </TableCell>
      <TableCell
        className="bw-border-b bw-pr-2 bw-text-right bw-bg-background-container bw-w-max bw-border-border-primary bw-text-xs bw-font-bold bw-text-text-primary"
        component="th"
        scope="row"
      >
        ${isNaN(row.value) ? 0 : row.value < 0.1 ? 0 : truncate(row.value, 4)}
        <div className="bw-flex bw-items-center bw-mt-1 bw-justify-end bw-gap-x-1">
          <p className="bw-text-right  bw-text-text-secondary  bw-font-bold bw-text-xs">
            {truncate(row.percentShare * 100, 4) || 0}%
          </p>
          <div
            style={{ background: row.color }}
            className="bw-w-[10px] bw-min-w-[10px] bw-h-[10px] bw-min-h-[10px]"
          ></div>
        </div>
      </TableCell>
    </TableRow>
  );
}
