import { CircularProgress } from "@mui/material";
import useStore from "../../../zustand/store";
import Lottie from "lottie-react";
import SpinnerAnimation from "../../../lottie/spinner.json";
export default function SpinnerComp() {
  const configuration = useStore((state) => state.configuration);
  return (
    // <div
    //   style={{
    //     color: configuration?.gradientStyle?.spinnerColor
    //       ? configuration.gradientStyle?.spinnerColor
    //       : "#2CFFE4",
    //   }}
    //   className="bw-w-full bw-flex bw-justify-center bw-mt-10 "
    // >
    //   <CircularProgress size={24} thickness={4} color="inherit" />
    // </div>
    <div className="bw-w-full bw-h-[24px]  bw-flex bw-justify-center bw-mt-10 ">
      <Lottie animationData={SpinnerAnimation} loop={true} />
    </div>
  );
}
