import { useAccount, useDisconnect, useNetwork, useBalance } from "wagmi";
import { useDynamicContext } from "@dynamic-labs/sdk-react-core";
import { useWallet } from "@tronweb3/tronwallet-adapter-react-hooks";
export default function useWalletDetails({ chainData, fromChain, toChain }) {
  //evm
  const { address, isConnected } = useAccount();
  const { data } = useBalance({ address });
  const { disconnect } = useDisconnect();
  const { chain } = useNetwork();
  let evmWallet = { address, data, disconnect, chain };

  //solana
  const { isFullyConnected, primaryWallet, handleLogOut } = useDynamicContext();
  let solWallet = {
    address: primaryWallet?.address || "",
    disconnect: handleLogOut,
    chain: { network: "Solana" },
  };

  //tron
  const {
    address: tronAdrress,
    disconnect: tronDisconnect,
    connected,
  } = useWallet();
  let tronWallet = {
    address: tronAdrress || "",
    disconnect: tronDisconnect,
    chain: { network: "tron" },
  };
  const walletAddress = {
    evm: { address: address, connected: isConnected, name: "Ethereum" },
    sol: {
      address: primaryWallet?.address || "",
      connected: isFullyConnected,
      name: "Solana",
    },
    tron: { address: tronAdrress, connected: connected, name: "Tron" },
  };
  function handleConnection() {
    if (chainData)
      return (
        (chainData?.networkType === "evm" && isConnected) ||
        (chainData?.networkType === "sol" &&
          isFullyConnected &&
          primaryWallet !== null) ||
        (chainData?.networkType === "tron" && connected)
      );
    else return;
  }
  function handleEVMDisable(connectWalletField) {
    if (
      (connectWalletField === "from" &&
        (fromChain?.networkType?.toLowerCase() === "sol" ||
          fromChain?.networkType?.toLowerCase() === "tron")) ||
      (connectWalletField === "to" &&
        (toChain?.networkType?.toLowerCase() === "sol" ||
          toChain?.networkType?.toLowerCase() === "tron"))
    ) {
      return true;
    } else {
      return false;
    }
  }
  function handleSOLDisable(connectWalletField) {
    if (
      (connectWalletField === "from" &&
        (fromChain?.networkType?.toLowerCase() === "evm" ||
          fromChain?.networkType?.toLowerCase() === "tron")) ||
      (connectWalletField === "to" &&
        (toChain?.networkType?.toLowerCase() === "evm" ||
          toChain?.networkType?.toLowerCase() === "tron"))
    ) {
      return true;
    } else {
      return false;
    }
  }
  function handleTRONDisable(connectWalletField) {
    if (
      (connectWalletField === "from" &&
        (fromChain?.networkType?.toLowerCase() === "sol" ||
          fromChain?.networkType?.toLowerCase() === "evm")) ||
      (connectWalletField === "to" &&
        (toChain?.networkType?.toLowerCase() === "sol" ||
          toChain?.networkType?.toLowerCase() === "evm"))
    ) {
      return true;
    } else {
      return false;
    }
  }
  function getWallet(chainData) {
    if (chainData)
      if (chainData?.networkType === "evm") {
        return evmWallet;
      } else if (chainData?.networkType === "sol") {
        return solWallet;
      } else if (chainData?.networkType === "tron") {
        return tronWallet;
      } else {
        return {};
      }
    else {
      return;
    }
  }
  function getBothWalletConnection(fromNetwork, toNetwork) {
    if (fromNetwork && toNetwork)
      return walletAddress[fromNetwork].address?.length &&
        walletAddress[toNetwork].address?.length
        ? true
        : false;
    else {
      return false;
    }
  }

  function getConnectWallet(fromNetwork, toNetwork) {
    if (fromNetwork && toNetwork) {
      if (!walletAddress[fromNetwork]?.connected) {
        return walletAddress[fromNetwork]?.name;
      } else if (!walletAddress[toNetwork]?.connected) {
        return walletAddress[toNetwork]?.name;
      } else {
        return "";
      }
    } else {
      return "";
    }
  }
  return {
    isAnyWalletConnected: handleConnection(),
    getWallet,
    handleEVMDisable,
    handleSOLDisable,
    handleTRONDisable,
    getBothWalletConnection,
    getConnectWallet,
  };
}
