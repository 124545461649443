import React, { useState } from "react";
import DisconnectIcon from "../../Svg/disconnecticon";
import getWalletIcons from "../../../utils/getWalletIcons";
export default function WalletDetailsPortfolio({
  walletDetails,
  walletName,
  i,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const { address, chain, data, disconnect, ...rest } = walletDetails;
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <button
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        id="basic-button"
        style={{ fontSize: "12px" }}
        className={`bw-bg-background-container ${
          i
            ? "bw-border-0 "
            : " bw-border-0 bw-border-b-[0.5px] bw-border-border-primary"
        } bw-flex  bw-items-center bw-justify-between bw-py-2 bw-gap-x-1  bw-w-full   `}
      >
        {getWalletIcons()?.[walletName] ? (
          <img
            src={getWalletIcons()[walletName]}
            width={26}
            height={26}
            className="bw-border bw-max-w-[26px] bw-max-h-[26px] bw-min-w-[26px] bw-min-h-[26px]  bw-border-border-primary bw-rounded-[50%] bw-bg-background-secondary"
          />
        ) : (
          <></>
        )}
        <p className="bw-text-text-primary bw-text-xs bw-font-normal">
          {address?.substring(0, 4) +
            "..." +
            address?.substring(address.length - 4, address.length)}
        </p>
        <div
          onClick={() => {
            disconnect();
          }}
        >
          {/* <img src={disconnecticon} /> */}
          <DisconnectIcon fill={"var(--tw-text-secondary)"} />
        </div>
      </button>
    </>
  );
}
