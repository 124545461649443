import WidgetContainer from "./Components/WidgetContainer";
import { configureChains } from "@wagmi/core";
import { publicProvider } from "@wagmi/core/providers/public";
import {
  mainnet,
  polygon,
  optimism,
  goerli,
  sepolia,
  polygonMumbai,
  bsc,
  zkSync,
  base,
  arbitrum,
  fantom,
  avalanche,
  polygonZkEvm,
  fuse,
  boba,
  okc,
  moonriver,
  aurora,
  linea,
  gnosis,
  moonbeam,
  cronos,
  evmos,
  telos,
  harmonyOne,
} from "@wagmi/core/chains";
import { WalletProvider } from "@tronweb3/tronwallet-adapter-react-hooks";
import { TronLinkAdapter } from "@tronweb3/tronwallet-adapters";
import "./index.css";
import { alchemyProvider } from "@wagmi/core/providers/alchemy";
import { WagmiConfig, createConfig } from "wagmi";
import { MetaMaskConnector } from "@wagmi/connectors/metaMask";
import { WalletConnectConnector } from "@wagmi/core/connectors/walletConnect";
import { InjectedConnector } from "wagmi/connectors/injected";
import { CoinbaseWalletConnector } from "wagmi/connectors/coinbaseWallet";
import { infuraProvider } from "@wagmi/core/providers/infura";
import { QueryClient, QueryClientProvider } from "react-query";
import { DynamicContextProvider } from "@dynamic-labs/sdk-react-core";
import { Buffer } from "buffer";
import { SolanaWalletConnectors } from "@dynamic-labs/solana";
import { useEffect, useMemo } from "react";
import useStore from "./zustand/store";
import { isEmpty } from "lodash";
import getParams from "./utils/getParams";
import ToggleButton from "./Components/Toggle";

const queryClient = new QueryClient();
function ZWidget({ gradientColor }) {
  const setGradient = useStore((state) => state.setGradient);
  const setConfiguration = useStore((state) => state.setConfiguration);
  const configuration = useStore((state) => state.configuration);
  const theme = useStore((state) => state.theme);
  const adapters = useMemo(() => [new TronLinkAdapter()]);
  const { chains, publicClient, webSocketPublicClient } = configureChains(
    [
      mainnet,
      polygonMumbai,
      polygon,
      optimism,
      goerli,
      sepolia,
      bsc,
      zkSync,
      base,
      arbitrum,
      fantom,
      avalanche,
      polygonZkEvm,
      fuse,
      optimism,
      boba,
      okc,
      moonriver,
      aurora,
      linea,
      gnosis,
      moonbeam,
      cronos,
      evmos,
      telos,
      harmonyOne,
    ],

    [
      alchemyProvider({ apiKey: "UPTSl0S8hg5frz84jM4q1xc1pJzjHL86" }),
      infuraProvider({ apiKey: "509ce1bf287447d8b4c5c9f1c034b251" }),
      publicProvider(),
    ]
  );
  useEffect(() => {
    const { fromChain, fromCoin, toChain, toCoin, backgroundColor } =
      getParams();
    setConfiguration({
      // gradientStyle: {
      //   background: "linear-gradient(#e66465, #9198e5)",
      //   spinnerColor: "#e66465",
      //   stopColor: "#9198e5",
      // },

      containerStyle: {},
      // fontFamily: fontFamily ? fontFamily : "",
      theme,
      customTheme: {
        text: {
          primary: "",
          secondary: "",
          selected: "",
          button: "",
          success: "",
          error: "",
        },
        background: {
          container: backgroundColor || "",
          secondary: "",
          networkCard: "",
          disable: "",
          loaderbar: "",
        },
        border: {
          primary: "",
          secondary: "",
          inputHighlight: "",
        },
        // shadow: {
        //   boxShadow: "1px 1px 7px 5px rgb(255,255,255,0.1)",
        // },
      },
      defaultChains: {
        from: { chainId: fromChain ? fromChain : "137" },
        to: { chainId: toChain ? toChain : "56" },
      },
      defaultTokens: {
        from: {
          tokenAddress: fromCoin
            ? fromCoin
            : "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
        },
        to: {
          tokenAddress: toCoin
            ? toCoin
            : "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
        },
      },
    });
  }, [theme]);
  useEffect(() => {
    document.documentElement.style.setProperty(
      "--tw-text-primary",
      configuration?.customTheme?.text?.primary || ""
    );
    document.documentElement.style.setProperty(
      "--tw-text-secondary",
      configuration?.customTheme?.text?.secondary || ""
    );
    document.documentElement.style.setProperty(
      "--tw-text-selected",
      configuration?.customTheme?.text?.selected || ""
    );
    document.documentElement.style.setProperty(
      "--tw-text-button",
      configuration?.customTheme?.text?.button || ""
    );
    document.documentElement.style.setProperty(
      "--tw-text-greentext",
      configuration?.customTheme?.text?.success || ""
    );
    document.documentElement.style.setProperty(
      "--tw-text-redtext",
      configuration?.customTheme?.text?.error || ""
    );

    document.documentElement.style.setProperty(
      "--tw-background-container",
      configuration?.customTheme?.background?.container || ""
    );
    document.documentElement.style.setProperty(
      "--tw-background-secondary",
      configuration?.customTheme?.background?.secondary || ""
    );
    document.documentElement.style.setProperty(
      "--tw-background-networkcard",
      configuration?.customTheme?.background?.networkCard || ""
    );
    document.documentElement.style.setProperty(
      "--tw-background-disable",
      configuration?.customTheme?.background?.disable || ""
    );
    document.documentElement.style.setProperty(
      "--tw-background-loaderbar",
      configuration?.customTheme?.background?.loaderbar || ""
    );

    document.documentElement.style.setProperty(
      "--tw-border-primary",
      configuration?.customTheme?.border?.primary || ""
    );
    document.documentElement.style.setProperty(
      "--tw-border-secondary",
      configuration?.customTheme?.border?.secondary || ""
    );
    document.documentElement.style.setProperty(
      "--tw-border-inputHighlight",
      configuration?.customTheme?.border?.inputHighlight || ""
    );
  }, [configuration]);
  useEffect(() => {
    if (configuration.theme === "dark") {
      document.documentElement.setAttribute("data-theme", "dark");
    } else {
      document.documentElement.classList.remove("dark");
      document.documentElement.setAttribute("data-theme", "light");
    }
  }, [configuration.theme]);
  useEffect(() => {
    window.Buffer = Buffer;
    if (!isEmpty(configuration)) {
      setConfiguration(configuration);
    }
    setGradient(gradientColor);
  }, [configuration, gradientColor]);
  const config = createConfig({
    autoConnect: true,
    publicClient,
    connectors: [
      new InjectedConnector({
        chains,
        options: { name: "Browser Wallet" },
        shimDisconnect: true,
      }),
      new MetaMaskConnector({ chains, shimDisconnect: true }),
      new CoinbaseWalletConnector({
        chains,
        shimDisconnect: true,
        options: {
          appName: "blockend",
        },
      }),
      new WalletConnectConnector({
        chains,
        options: {
          projectId: "a3cc5b84df95db911e2f9f9655114425",
        },
        shimDisconnect: true,
      }),
    ],
    webSocketPublicClient,
    publicClient,
  });
  function onError(error) {
    console.log(error, "error");
  }
  return (
    <QueryClientProvider client={queryClient} contextSharing={true}>
      <WagmiConfig config={config}>
        <DynamicContextProvider
          settings={{
            environmentId: "5d12d5a9-6206-433b-85b8-25fc9f0da5e3",
            walletConnectors: [SolanaWalletConnectors],
            initialAuthenticationMode: "connect-only",
          }}
        >
          <WalletProvider adapters={adapters} onError={onError}>
            {/* <ToggleButton /> */}
            <WidgetContainer />
          </WalletProvider>
        </DynamicContextProvider>
      </WagmiConfig>
    </QueryClientProvider>
  );
}

export default ZWidget;
